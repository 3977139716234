import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import Layout from '../components/layout'
import Section from '../components/section'
import Seo from '../components/seo'
import Hero from '../components/hero'
import SubscribeForm from '../components/subscribe-form'
import UnstyledList from '../components/unstyled-list'

import SmoresIllustration from '../images/smores-illustration.svg'

const GiveawayPage = () => {
  const socialImageAlt = `s’mores giveaway`

  const metaDescription = `Sign up for a chance to win a large variety of ` +
    `s’mores prizes. Includes a t-shirt, Caribou Coffee, gourmet s’mores ` +
    `kit, indoor s’mores maker, ChapStick & more.`

  return (
    <Layout headerTheme="chocolate">
      <Seo
        title="S’mores Everything Giveaway"
        description={metaDescription}
        canonicalUrl="https://www.smorescout.com/giveaway/"
        meta={[
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:image',
            content: 'https://www.smorescout.com/images/promo/giveaway-2020-open-graph.jpg',
          },
          {
            property: 'og:image:alt',
            content: socialImageAlt,
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            name: 'twitter:image',
            content: 'https://www.smorescout.com/images/promo/giveaway-2020-twitter.jpg',
          },
          {
            name: 'twitter:image:alt',
            content: socialImageAlt,
          },
          {
            name: 'robots',
            content: 'noindex,follow',
          }
        ]}
      />

      <div style={{ position: 'relative' }}>
        <div style={{
          position: 'absolute',
          top: '65%',
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: '#F3EBDC'
        }} />

        <Hero>
          <Container>
            <Row className="justify-content-around align-items-center">
              <Col xs={12}>
                <h1 className="hero-giveaway-head text-center mb-3 pb-3">
                  <small><strong>Enter to win the</strong></small><br />
                  <span className="hashtag">
                    <span className="hashtag-word">
                      S’mores
                    </span>{' '}
                    <span className="hashtag-word">
                      Everything
                    </span>
                  </span>{' '}
                  Giveaway
                </h1>
              </Col>

              <Col xs={12} xl={10} className="mb-4 mb-md-0 mx-xl-5">
                <p>
                  <img
                    src="/images/promo/giveaway-2020.jpg"
                    alt=""
                    className="mw-100 rounded-lg"
                  />
                </p>
              </Col>
            </Row>
          </Container>
        </Hero>
      </div>

      <Section className="pb-5" style={{ backgroundColor: '#F3EBDC' }}>
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={6} className="pb-4 pb-md-0 pr-lg-4">
              <figure style={{ maxWidth: '200px' }}>
                <SmoresIllustration />
              </figure>

              <h2 className="mb-4">
                The prize pack
              </h2>
              
              <div className="lead pt-2">
                <UnstyledList>
                  <UnstyledList.Item className="mb-3">
                    Nostalgia Indoor Electric Stainless Steel S'mores Maker
                  </UnstyledList.Item>
                  <UnstyledList.Item className="mb-3">
                    1927 S’mores Original S’mores Kit
                  </UnstyledList.Item>
                  <UnstyledList.Item className="mb-3">
                    “Official S’mores Tastetester” T-shirt (you choose color and
                    size)
                  </UnstyledList.Item>
                  <UnstyledList.Item className="mb-3">
                    SmashMallow Toasted Vanilla Marshmallows
                  </UnstyledList.Item>
                  <UnstyledList.Item className="mb-3">
                    Caribou S’mores Cabin ground coffee
                  </UnstyledList.Item>
                  <UnstyledList.Item className="mb-3">
                    Chesapeake Bay Aromascape Campfire and S’mores Jar Candle
                  </UnstyledList.Item>
                  <UnstyledList.Item className="mb-3">
                    ChapStick S’mores Collection
                  </UnstyledList.Item>
                  <UnstyledList.Item className="mb-3">
                    Quest Bar S’mores Protein Bar
                  </UnstyledList.Item>
                  <UnstyledList.Item className="mb-3">
                    Free shipping
                  </UnstyledList.Item>
                </UnstyledList>
              </div>
            </Col>

            <Col xs={12} md={6}>
              <div
                style={{ backgroundColor: '#80581C' }}
                className="p-3 p-sm-5 p-md-3 p-lg-4 p-xl-5 text-on-dark"
              >
                <h2 className="mb-4">
                  <span role="img" aria-label="trophy">🏆</span>{' '}
                  How to win
                </h2>

                <p className="lead">
                  <strong>The giveaway has ended as of October&nbsp;31, 2020, at
                  12:00&nbsp;pm EDT.</strong>
                </p>
                <p>
                  The winner will be selected randomly and will be notified on
                  {' '}
                  <strong>November&nbsp;1, 2020</strong>.
                </p>
                <p>
                  Winner must be a resident of the continental United States. No
                  purchase necessary to enter. (We don't sell anything anyway.
                  {' '}
                  <span role="img" aria-label="joy">😂</span>)
                </p>

                <h3 className="text-on-dark pt-3 pb-2">
                  Get notifications for future giveaways:
                </h3>
                <SubscribeForm />
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default GiveawayPage
